import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h2 futura bold color="#C60000" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            {/* <CFLink href="https://www.facebook.com/pages/Sushi%20Aria/1012844792180773/">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="88 Noodle House Facebook"
              />
            </CFLink> */}
            <CFLink href="https://www.yelp.ca/biz/88-palpal-noodle-house-burnaby">
              <CFImage
                w="45px"
                pr="10px"
                src={yelp}
                alt="88 Noodle House Yelp"
              />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/88-palpal-noodle-house-central-burnaby-burnaby">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="88 Noodle House Zomato"
              />
            </CFLink>
            <CFLink href="https://www.instagram.com/88noodles/">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="88 Noodle House Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h2 futura bold color="#C60000" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            {/* <CFLink href="https://www.facebook.com/pages/Sushi%20Aria/1012844792180773/">
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="88 Noodle House Facebook"
                hover
              />
            </CFLink> */}
            <CFLink href="https://www.yelp.ca/biz/88-palpal-noodle-house-burnaby">
              <CFImage
                w="45px"
                pr="10px"
                src={yelp}
                alt="88 Noodle House Yelp"
                hover
              />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/88-palpal-noodle-house-central-burnaby-burnaby">
              <CFImage
                w="45px"
                pr="10px"
                src={zomato}
                alt="88 Noodle House Zomato"
                hover
              />
            </CFLink>
            <CFLink href="https://www.instagram.com/88noodles/">
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="88 Noodle House Instagram"
                hover
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
